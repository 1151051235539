import React from "react";
import "reset-css";
import Link from "gatsby-link";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import classnames from "classnames";
import Nav from "../components/nav";
import styles from "./about.module.scss";
import Layout from "../components/layout";
import Button from "../components/button";

const App = () => (
  <Layout>
    <Helmet title="About • The Bronx Direct" />
    <Nav theme="dark" />
    <div className={styles.container}>
      <h1 className={styles.h1}>About this project</h1>
      <p>
        <strong>The Bronx Direct</strong> is a digital directory (and job board) of Bronx professionals, influencers, and community organizations on Twitter.
        We make discovering Bronxites easier by providing you with a categorized directory of people to follow and connect with. Our easy to digest job board 
        also makes its easier to discover career opportunities in your community.
      </p>

      <h2 className={styles.emphasis}>Our mission</h2>
      <p>To Connect the Bronx. The Bronx Direct exists because there is a disconnect between generations of Bronx leaders and industry professionals across the borough. 
      We're interested in creating a future where the Bronx is better connected and digitally empowered. We're also interested in connecting our professionals with opportunities, 
      paid and unpaid, within the borough so that we may support our own community.</p>

      <h2 className={styles.emphasis}>What's next?</h2>
      <p>Currently, the entries in our directory are from Bronxites that are Twitter users and that have been 
      <a 
      href="https://airtable.com/shrzPH8tYCilxN6MF"
      target="_blank"
      rel="noopener noreferrer"
      > nominated</a>. 
      With your support we're hoping to continue developing this 
      platform, and to include nominated Bronxites that don't have Twitter profiles as well.</p>

      <h1 className={styles.h1}>Support</h1>
      <h2 className={styles.emphasis}>Spread the word</h2>
      <p>If you own a website or blog, you can help support us by sharing a link to our directory or by giving us a shout out. 
      Otherwise, you can also support us by telling your friends about us. 😊</p>
      <h2 className={styles.emphasis}>Post on our Job Board</h2>
      <p>If you're a local small business or expanding business looking to hire Bronx talent, please do consider posting on our job board as well.</p>
      <h2 className={styles.emphasis}>Donate</h2>
      <p>
        The Bronx Direct is an independent initiative by BD Feliz. If you'd like to support please consider making a donation. Donations go towards paying for hosting, creating promos, and promoting this platform.
      </p>
      <Button
        href="https://cash.app/$BDF"
        width="auto"
      >
        Donate
      </Button>

      <h1 className={styles.h1}>Connect</h1>
      <h2 className={styles.emphasis}>Team</h2>
      <p>
        <a
          href="https://bdfeliz.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
        Brayan Daniel Feliz
        </a> (only me for now...){" "}
        <span role="img" aria-label="Peace hand emoji">
          ✌️
        </span>
        </p>
        <h2 className={styles.emphasis}>Reach out</h2>    
        <p>
        If you'd like to get involved or have a question, shoot us an <a 
          href="mailto:thebronxdirect@protonmail.com?subject=General%20Inquiry"
          target="_blank"
          rel="noopener noreferrer"
        >
        email here
        </a>.
      </p>

      <h1 className={styles.h1}>Opt out</h1>
      <p>
        If you've been featured in this directory and you'd rather not be, please
        send a DM to{" "}
        <a
          href="https://twitter.com/bronxdirect"
          target="_blank"
          rel="noopener noreferrer"
        >
          @BronxDirect
        </a>{" "}
        on Twitter and you will be removed.
      </p>   

      <div className={styles.backContainer}>
        <Link to="/" className={styles.backLink}>
          HOME
        </Link>
      </div>
    </div>
  </Layout>
);

export default App;
